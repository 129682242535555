import { __decorate } from "tslib";
import { Component, Vue, Watch } from 'vue-property-decorator';
import { WorkPlatformService } from '@/sevices/index';
import { namespace } from 'vuex-class';
const workPlatformModule = namespace('workPlatform');
let ComponentName = class ComponentName extends Vue {
    constructor() {
        super(...arguments);
        this.pagination = {
            // 总条数
            total: 0,
            // 当前页
            page: 1,
            // 总页码
            pageTotal: 0,
        };
        /**
         * app数据
         */
        this.apps = {};
        this.onlineAppList = [];
        /**
         * 搜索参数
         */
        this.params = {
            keywork: '',
            page: 1,
            size: 14,
        };
        this.onlineApps = [];
    }
    // 切换成功组织后再去获取数据
    async onChangeIsSetOrg(v) {
        if (v) {
            await this.getAppList({});
            this.setOlineApps(this.onlineAppList);
        }
    }
    /**
     * 搜索
     */
    search(searchKey) {
        const params = {
            ...this.params,
            page: 1,
            keywork: searchKey,
        };
        this.params = params;
        this.apps = {};
        this.getAppList(params);
    }
    /**
     * 获取应用列表
     */
    async getAppList(query) {
        const params = {
            ...this.params,
            ...query,
        };
        this.params = params;
        const res = await WorkPlatformService.getAppList(params);
        if (res.success) {
            const total = res.total;
            const size = this.params.size;
            this.onlineAppList = res.data;
            let pageTotal = 0;
            if (total && size) {
                pageTotal = Math.ceil(total / size);
            }
            this.pagination.total = total || 0;
            this.pagination.pageTotal = pageTotal || 0;
            // 设置轮播页
            this.apps = {};
            if (Object.keys(this.apps).length === 0 && pageTotal > 0) {
                const obj = {};
                for (let index = 0; index < pageTotal; index++) {
                    obj[index + 1] = [];
                }
                this.apps = obj;
            }
            // 设置轮播页数据
            const data = res.data;
            if (Array.isArray(data) && data.length) {
                const page = this.params.page;
                if (!page)
                    return;
                this.$set(this.apps, page, data);
                // this.onlineApps = [...this.onlineApps, ...data];
            }
        }
        else {
            this.apps = {};
        }
    }
    /**
     * 轮播切换
     */
    slideBeforeChange(from, to) {
        const page = to + 1;
        if (this.pagination.pageTotal < page || to === -1) {
            return;
        }
        if (this.apps[page] && this.apps[page].length) {
            return;
        }
        const params = {
            page: page,
        };
        this.getAppList(params);
    }
};
__decorate([
    workPlatformModule.Action('setOlineApps')
], ComponentName.prototype, "setOlineApps", void 0);
__decorate([
    workPlatformModule.State('isSetOrg')
], ComponentName.prototype, "isSetOrg", void 0);
__decorate([
    workPlatformModule.State('recentApps')
], ComponentName.prototype, "recentApps", void 0);
__decorate([
    Watch('isSetOrg', { immediate: true })
], ComponentName.prototype, "onChangeIsSetOrg", null);
ComponentName = __decorate([
    Component({
        name: 'app-list',
        components: {
            AppPage: () => import('./app-page'),
        },
    })
], ComponentName);
export default ComponentName;
